<template>
  <v-sheet
    rounded
    :color="
            'blue-grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')
        "
    class="pa-4 mb-4"
  >
    <vuetify-google-autocomplete
      v-model="searchText"
      :id="'google-address-autocomplete-search-'+ Math.random().toString(36).substr(2, 9)"
      placeholder="Search for address..."
      country="au"
      v-on:placechanged="addressSelected"
      :autofocus="autofocus"
      solo
      :solo-inverted="$vuetify.theme.isDark"
      hide-details
      clearable
    />
    <v-row dense class="pt-4">
      <v-col cols="12" sm="6">
        <a v-if="showManualEntry" @click="$emit('manual')">Click here to manually enter address details</a>
      </v-col>
      <v-col cols="12" sm="6" class="text-right text--disabled">
        Search powered by <a href="https://maps.google.com/" target="_blank">Google Maps</a
      >
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
//google-address-autocomplete-search
'id-' + Math.random().toString(36).substr(2, 9);
const StreetTypeRegex = /^([A-Za-z](?:(?:[A-Za-z ]+)?[A-Za-z])?)(?: (Alley|Arcade|Avenue|Boulevard|Bypass|Circuit|Close|Corner|Court|Crescent|Cul-de-sac|Drive|Esplanade|Green|Grove|Highway|Junction|Lane|Link|Mews|Parade|Place|Ridge|Road|Square|Street|Terrace))$/i;
const StreetSuffixRegex = /^([A-Za-z](?:(?:[A-Za-z ]+)?[A-Za-z])?)(?: (Central|East|Extension|Lower|(?:North|South)(?: (?:East|West))?|Upper|West))$/i;
export default {
  name: 'AddressGoogleAutocomplete',
  data: () => ({
    searchText: null,
    searchResult: null,
  }),
  methods: {
    addressSelected(data) {
      if (!data) return;
      this.searchResult = data;
      let address = {
        sub_dwelling_number: '',
        street_number: data.street_number || '',
        street_name: '',
        street_type: '',
        street_suffix: '',
        suburb: data.locality || '',
        state: data.administrative_area_level_1 || '',
        postcode: data.postal_code || ''
      }
      if (data.route) {
        let route = data.route;
        let suffix = route.match(StreetSuffixRegex);
        if (suffix) {
          route = suffix[1];
          address.street_suffix = suffix[2];
        }
        let type = route.match(StreetTypeRegex);
        if (type) {
          route = type[1];
          address.street_type = type[2];
        }
        address.street_name = route;
      }
      if (data.name) {
        let name = data.name;
        if (name.search(address.street_name)) {
          name = name.substring(0, name.search(address.street_name)).trim();
        }

        let unit = false;
        if (/\bunit\b/i.test(name)){
          unit = true;
          //remove unit from the string
          name = name.replace(/\bunit\b/i, '').trim();
        }

        if (/^(\d+)\/\d+$/.test(name)) {
          let subnumber = name.match(/^(\d+)\/\d+$/);
          address.sub_dwelling_number = subnumber ? subnumber[1] : '';
          if(unit){
            //we set the building_type to unit
            address.building_type = 'U';
          }
        }
      }
      this.$emit('selected', address);
    },
  },
  watch: {
    value(value) {
      if (this.searchText !== value) this.searchText = value;
    },
    searchText(value) {
      if (!value) {
        this.searchResult = null;
      }
    }
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    showManualEntry: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.searchText = this.value;
  }
};
</script>